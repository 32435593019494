/* Utility functions for pages */

/**
 * Utility function to generate top-of-page links from the configured sections.
 * @param object - the site configuration object
 * @return array
 */
function makeTopLinks(config) {
  if (!(config && config.hasOwnProperty('sections') && Array.isArray(config.sections))) return null;
  // Note: links are not relative but point to root ("/")
  return config.sections.filter(sec => sec.hasOwnProperty('linkto')).map(sec => ({label:sec.title , to:`/${sec.linkto}`}));

}

/**
 * Sets container element heights which is needed with variable height child elements
 * (that are absolutely positioned -- as is the case with slide transitions).
 */
function adjustSlideHeight() {
  const activeSlide = document.querySelector('.slide.slide-active');
  const container = activeSlide.closest('.screener-section');
  const screener = activeSlide.closest('.screener');
  // console.log(`ADJUSTING ht = ${activeSlide.scrollHeight}, rendered ht = ${activeSlide.clientHeight}`, container.scrollHeight, container.clientHeight, activeSlide);
  const newHeight = activeSlide.clientHeight;
  screener.style.height=`${newHeight}px`;
  container.style.height=`${newHeight}px`;
}


/**
 * Return an event handler function that calls a specified function on ENTER keypress.
 * @param function
 * @return function
 */
function filterKeyEnter(handler) {
  return function(e) { if (e.keyCode===13) { handler(e); } };
}

/**
 * Return accessibility attributes for a clickable and visible, non-interactive element.
 * @param function - a function to be called on click and also on ENTER key presses
 * @return object - attributes to be applied to an element
 */
function onClickA11y(handler, tabidx) {
  return {
    role: 'button',
    tabIndex: tabidx || 0,
    onKeyDown: filterKeyEnter(handler),
    onClick: handler
  };
}

const pkg = { makeTopLinks, adjustSlideHeight, onClickA11y };
export default pkg;
