/**
 * LinkMenu produces an unordered list of links.
 * Supported props: links, listStyle, itemStyle, localizerfn
 */
import React from 'react';
import { Link } from 'gatsby';

export default function LinkMenu(props) {
  return (
    <ul className={props.listStyle || 'nav-list'}>
      {renderLinkItems(props.links, props.itemStyle, props.localizerfn)}
    </ul>
  );
};

/**
 * Render a list of items optionally specifying one or more class names.
 * @params array - list of link objects
 * @params string - class names in space separated list
 * @return array
 */
function renderLinkItems(links, itemStyle, localized) {
  return links.map((link, index) => {
    return (
      <li key={`li_${index}`} className={itemStyle || 'nav-item'}>{makeLink(link, index, localized)}</li>
    );
  });
}

function makeLink(link, index, localized) {
  const href = (localized ? localized(link.to) : link.to);
  return (link.to.indexOf('#')>=0 ? (<Link key={`link_${index}`} to={href}>{link.label}</Link>) :
                                    (<a key={`a_${index}`} href={link.to}>{link.label}</a>));
}
